<script setup>
import ChartLoader from "./ChartLoader.vue";
import {computed, ref} from "vue";

const props = defineProps({
  xAxisData: {
    type: JSON,
    default: {}
  },
  yAxisData: {
    type: JSON,
    default: {}
  },
  yAxisDataOptional: {
    type: Array,
    default: []
  },
  tooltips: {
    type: Array,
    default: []
  },
  title: {
    type: String,
    default: ''
  },
  xAxis: {
    type: String,
    default: 'Year'
  },
  yAxis: {
    type: String,
    default: null
  },
  yAxisOptional: {
    type: String,
    default: null
  },
  colors: {
    type: Array,
    default: ['#333333', '#aaaaaa']
  },
  chartId: {
    type: String,
    default: "myChart"
  },
  chartType: {
    type: String,
    default: 'bar'
  },
  showCumulative: {
    type: Boolean,
    default: false
  },
  buildingArea: {
    type: Number,
    default: 1
  }
});

const cumulativeActive = ref(false)

const littleCircleWithColor = computed(() => {

  const kpi = props.title.toLowerCase();
  let color;
  let icon;

  if (kpi.includes('€')) {
    color = '#549e39';
    icon = 'fa-eur pr-1';
  }
  else if (kpi.includes('mwh') || kpi.includes('kwh') || kpi.includes('live energy')) {
    color = '#1cade4';
    icon = 'fa-bolt';
  }
  else if (kpi.includes('emissions') || kpi.includes('global warming')) {
    color = '#e84c22';
    icon = 'fa-cloud';
  }
  else {
    return;
  }

  return `<span class='fa-stack'><i class='fa fa-circle-thin fa-stack-2x' aria-hidden='true' style='color: ${color}'></i><i class='fa ${icon} fa-stack-1x' aria-hidden='true' style='color: ${color}'></i></span>`;
});

const getCssSwitchClass = computed(() => {

  const kpi = props.title.toLowerCase();

  if (kpi.includes('€')) {
    return 'slider-costs'
  }
  else if (kpi.includes('mwh') || kpi.includes('kwh') || kpi.includes('live energy')) {
    return 'slider-energy'
  }
  else if (kpi.includes('emissions') || kpi.includes('global warming')) {
    return 'slider-emissions'
  }
  else {
    return '';
  }
});

/**
 * TODO: This is a duplicate from ChartLoader. Let's move this elsewhere when we can.
 */
const negativeValuesPresentInAnyDataset = computed(() => {

  if (props.yAxisData.length === 2) {
    return props.yAxisData[0].data.some(value => value < 0) ||
        props.yAxisData[1].data.some(value => value < 0);
  }

  return props.yAxisData[0].data.some(value => value < 0);
})

</script>

<template>

<div class="row generic-chart" style="background: white; border-radius: 5px; padding-bottom: 10px; padding-top: 5px; box-shadow: 2px 2px 5px #aaa; width: 100%;">
  <div class="col-12">
    <span class="position-absolute" v-html="littleCircleWithColor"></span>

    <div>
      <ChartLoader
          :x-axis-data="props.xAxisData"
          :y-axis-data="props.yAxisData"
          :y-axis-data-optional="props.yAxisDataOptional"
          :tooltips="props.tooltips"
          :title="props.title"
          :y-axis="props.yAxis"
          :y-axis-optional="props.yAxisOptional"
          :x-axis="props.xAxis"
          :chart-id="props.chartId"
          :show-cumulative="props.showCumulative"
          :chart-type="props.chartType"
          :colors="props.colors"
          :building-area="props.buildingArea"
          ref="chartLoader"
      >
      </ChartLoader>
    </div>

    <div class="d-flex mt-3 mb-1 justify-content-start">
      <label class="switch">
        <input type="checkbox"
          @change="$refs.chartLoader.onSwitchChange"
               v-model="cumulativeActive"
        >
        <span :class="'slider round ' + getCssSwitchClass"></span>
      </label>
      <div class="label text-center" >Show cumulative</div>
    </div>
    <div v-if="negativeValuesPresentInAnyDataset && cumulativeActive">
      <i class="text-danger fa fa-exclamation-triangle"></i>
      <small class="text-danger p-1">Logarithmic scales are unavailable in datasets with negative values.</small>
    </div>
  </div>
</div>
</template>

<style scoped>

input:checked + .slider-energy {
  background-color: #1cade4
}

input:focus + .slider-energy {
  box-shadow: 0 0 1px #1cade4
}

input:checked + .slider-emissions {
  background-color: #e84c22
}

input:focus + .slider-emissions {
  box-shadow: 0 0 1px #e84c22
}

input:checked + .slider-costs {
  background-color: #549e39
}

input:focus + .slider-costs {
  box-shadow: 0 0 1px #549e39
}

</style>
