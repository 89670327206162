<template>
  <table
    class="table text-center"
  >
    <thead class="thead-primary">
      <tr>
        <th rowspan="2" style="vertical-align: middle; font-size: 120%;" class="text-left">KPI</th>
        <th v-for="name in scenarioNames" :key="name" colspan="2">{{ name }}</th>
        <th v-if="scenarioNames.length > 1" rowspan="2" style="vertical-align: middle;">Difference</th>
      </tr>
      <tr>
        <th v-for="secondaryHeader in secondaryHeaders" :key="secondaryHeader">{{ secondaryHeader }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(rowData, rowIndex) in data"
        :key="`${datasetIndex}_${rowIndex}`"
        :style="{ backgroundColor: getRowBackgroundColor(rowData[0], rowIndex) }"
      >
        <td
          v-html="getFontAwesomeIconAndColor(rowData[0]) + getKpiName(rowData[0]) + getKpiTooltip(rowData[0])"
          style="font-weight: bold"
          class="text-left"
        ></td>
        <td
          v-for="(cellData, cellIndex) in rowData.slice(1)"
          :key="`${datasetIndex}_${rowIndex}_${cellIndex}`"
          :colspan="colspanNumber(rowData)"
        >
          <span :class="cellData.toString().includes('%') && !cellData.toString().includes('-') ? 'text-danger' : ''">{{ cellData }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
// ----------------------------------------------
// Component Name: KpisTable
// Purpose: This component displays a dynamic table that includes the total values of the KPIs.
//          The number of rows and columns are specified by the props passed in the component.
//
// Props:
//  - datasetIndex (Number, required): The dataset index in the parent component. The parent component keeps
//                                     track of all the datasets that this table will display at some point and
//                                     each dataset has a specific index. We pass that index here in order to create
//                                     unique keys in the for loops. @note not sure that this is required.
//  - tableData (Array, required): The data that the table should display. The data should be a 2D array.
//                                  Each item of the array represents a row in the table, and each item
//                                  of a nested array represents a value in a cell of a table. The first
//                                  item of the Array will be considered to be the header of the table while
//                                  the rest of the items will be a table row. The first column of each data row
//                                  will be also treated as a header and will be given an icon and bold font.
// ----------------------------------------------
import {computed, onUpdated} from "vue";

const props = defineProps({
  datasetIndex: {
    type: Number,
    default: null
  },
  tableData: {
    type: Array,
    default: () => [],
  },
});

const scenarioNames = computed(() => {
  return props.tableData.scenarioNames
})

const secondaryHeaders = computed(() => {
  if (scenarioNames.value.length === 1) {
    return ['Total', 'Annual avg.']
  }
  return ['Total', 'Annual avg.', 'Total', 'Annual avg.']
})

const data = computed(() => {
  return props.tableData.tableData
})

// The maximum number of columns, i.e. how many the headers are.
// If a row data items are less than that then we will have to merge cells.
// Used in the case of comparative KPIs.
const maxColumnsNo = computed(() => {
  return scenarioNames.value.length === 1 ? 6 : 3
});

onUpdated(() => { updateBootstrapTooltips() })

/**
 * This function updates all Bootstrap tooltips on the page by reinitializing them.
 * For each tooltip element in the page, it disposes of any previously initialized tooltip to
 * avoid duplication or stale tooltips, and then reinitializes the tooltip using a Bootstrap's build-in method.
 *
 * It was created because upon data change the tooltips did not work as expected. In more detail
 * the message did not appear instantly after hovering on the tooltip. This function is called
 * whenever dynamic content that includes tooltips is added, removed, or modified, to ensure the
 * tooltips behave as expected.
 */
function updateBootstrapTooltips() {
  const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');

  tooltips.forEach((el) => {
    $(el).tooltip('dispose');
    $(el).tooltip();
  });
}

function colspanNumber(rowData) {
  return rowData.length < maxColumnsNo ? maxColumnsNo - rowData.length : 1;
}

/**
 * Responsible for displaying the icon with its color in the left-side before the KPI Name.
 * @param kpi The KPI name. E.g., it might be "Lifetime Primary Energy Demand".
 * @return {string} An HTML span element which contains the classes required to display the icon.
 */
const getFontAwesomeIconAndColor = (kpi) => {
  const kpiName = getKpiName(kpi).toLowerCase();
  const generalizedKpi = getGeneralizedKpiObject(kpiName);

  let color = "";
  let icon = "";

  switch (generalizedKpi) {

    // energy has a light blue color and a thunderbolt icon.
    case GeneralizedKpiName.ENERGY:
      color = "#1cade4";
      icon = "fa-bolt";
      break;

    // emissions have a red icon and a cloud icon.
    case GeneralizedKpiName.EMISSIONS:
      color = "#e84c22";
      icon = "fa-cloud";
      break;

    // costs have a green icon and a euro sign.
    case GeneralizedKpiName.COSTS:
      color = "#549e39";
      icon = "fa-eur pr-1";
      break;

    // payback periods and everything else, don't have icons.
    default:
      return '';

  }

  return `<span class='fa-stack'>
            <i class='fa fa-circle-thin fa-stack-2x' aria-hidden='true' style='color: ${color}'></i>
            <i class='fa ${icon} fa-stack-1x' aria-hidden='true' style='color: ${color}'></i>
          </span> `;
};

/**
 * Responsible for showing the correct background in each KPI row. Some of them have interchanging colors.
 * This functions makes sure that it covers all scenarios regarding colors:
 * + Energy KPIs have a light-blue color
 * + Emissions KPIs have a light-red color
 * + Costs KPIs have a light-green color
 * + Emission Payback Periods have a strong red color.
 * + Cost Payback Periods have a stronger green color.
 *
 * If none of the criteria above is met, then there will be no color.
 * @param kpi The KPI name of the row.
 * @param rowIndex The index of the row. Helps to make the colors interchanging.
 * @return {string} a color hex string.
 */
const getRowBackgroundColor = (kpi, rowIndex) => {
  const kpiName = getKpiName(kpi).toLowerCase();
  const generalizedKpi = getGeneralizedKpiObject(kpiName);

  const pe_colors = ["#f9f9ff", "#f1f1ff"];
  const ghg_colors = ["#fff4f4", "#ffe9e9"];
  const costs_colors = ["#f9fff9", "#f1fff1"];

  switch (generalizedKpi) {

    // payback costs have a single stronger green color.
    case GeneralizedKpiName.PAYBACK_COSTS:
      return "#edfbd1";

    // payback GHG emissions have a single stronger red color.
    case GeneralizedKpiName.PAYBACK_EMISSIONS:
      return "#ffb8a7";

    // energy KPIs have an interchanging light-blue color.
    case GeneralizedKpiName.ENERGY:
      return pe_colors[rowIndex % 2];

    // emission KPIs have an interchanging light-red color.
    case GeneralizedKpiName.EMISSIONS:
      return ghg_colors[rowIndex % 2];

    // costs have an interchanging light-green color.
    case GeneralizedKpiName.COSTS:
      return costs_colors[rowIndex % 2];

    // the default case is no color returned.
    default:
      return '';
  }

};

/**
 * Responsible for displaying the correct KPI name. The variables from the back-end are often passed
 * as hashed. So this function makes sure that it always returns a string.
 * @param kpi The KPI might be an Object or it might be a String.
 * @return {string} The KPI string name.
 */
const getKpiName = (kpi) => {
  return kpi instanceof Object? kpi.kpi : kpi
};

/**
 * Responsible for generalizing a KPI to a point which can be grouped. This is done to make less checks
 * and generalize the code a bit more. {@linkcode GeneralizedKpiName}
 * @param kpiName The name of the KPI (string)
 * @return {number|number|null} PAYBACK_COSTS, PAYBACK_EMISSIONS, ENERGY, EMISSIONS, COSTS.
 */
const getGeneralizedKpiObject = (kpiName) => {

  if (kpiName.includes("payback")) {
    return kpiName.includes("emissions")? GeneralizedKpiName.PAYBACK_EMISSIONS : GeneralizedKpiName.PAYBACK_COSTS ;
  }
  if (kpiName.includes("€") || kpiName.includes("costs")) {
    return GeneralizedKpiName.COSTS;
  }
  else if (
      kpiName.includes("mwh") ||
      kpiName.includes("kwh") ||
      kpiName.includes("energy")
  ) {
    return GeneralizedKpiName.ENERGY;
  }
  else if (
      kpiName.includes("emissions") ||
      kpiName.includes("global warming")
  ) {
    return GeneralizedKpiName.EMISSIONS;
  }

  return null;
}

/**
 * Responsible for showing an 'i' icon right next to the KPI name with a tooltip text that shows when hovered.
 * @param kpi The KPI name which will retrieve the tooltip.
 * @return {string} An HTML element with the tooltip text.
 */
const getKpiTooltip = (kpi) => {
  const actual_tooltip = kpi instanceof Object? kpi.tooltip : kpi

  return `<span type="button" class="btn" data-toggle="tooltip" data-placement="top" title="${actual_tooltip}" style="color: inherit">
    <i class="fa fa-info-circle" aria-hidden="true"></i>
  </span>`;
}

const GeneralizedKpiName = Object.freeze({
  ENERGY: 0,
  EMISSIONS: 1,
  COSTS: 2,
  PAYBACK_EMISSIONS: 3,
  PAYBACK_COSTS: 4,
})

</script>

<style scoped>
/* Ensure FontAwesome styles are available in your project */
</style>
