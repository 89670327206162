<script setup>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController, LogarithmicScale
} from "chart.js";

import zoomPlugin from 'chartjs-plugin-zoom';
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    BarController,
    PointElement,
    LogarithmicScale,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const props = defineProps({
  xAxisData: {
    type: JSON,
    default: {}
  },
  yAxisData: {
    type: JSON,
    default: {}
  },
  yAxisDataOptional: {
    type: Array,
    default: []
  },
  tooltips: {
    type: Array,
    default: []
  },
  title: {
    type: String,
    default: ''
  },
  xAxis: {
    type: String,
    default: 'Year'
  },
  yAxis: {
    type: String,
    default: null
  },
  yAxisOptional: {
    type: String,
    default: null
  },
  colors: {
    type: Array,
    default: ['#333333', '#aaaaaa']
  },
  chartId: {
    type: String,
    default: "myChart"
  },
  chartType: {
    type: String,
    default: 'bar'
  },
  showCumulative: {
    type: Boolean,
    default: false
  },
  buildingArea: {
    type: Number,
    default: 1
  }
});

onBeforeMount(() => {});
onMounted(() => createGraph());

let chart;
let displayCumulative = false;
const hasTitleAbbreviation = ref(false)

watch(hasTitleAbbreviation, (newValue, _) => {
  if(newValue) {
    updateYAxisTitle(chart);
    chart.update()
  }
})

/**
 * Helper for the cumulative results. Helps decide the type of the datasets (logarithmic or linear). If there is at
 * least one value negative in any of the datasets, the logarithmic scale won't work.
 *
 * @return True if there is at least one negative value in any of the datasets (baseline, renovation), False otherwise.
 */
const negativeValuesPresentInAnyDataset = computed(() => {

  if (props.yAxisData.length === 2) {
    return props.yAxisData[0].data.some(value => value < 0) ||
        props.yAxisData[1].data.some(value => value < 0);
  }

  return props.yAxisData[0].data.some(value => value < 0);
})

/**
 * Creates the graph by instantiating a Chart JS object, which targets the canvas element with the
 * `chartId` ID. This function is called through `onMounted` which means that it only gets
 * called whenever the component is ready to be displayed.
 */
function createGraph() {
  if (chart !== undefined)
    chart.destroy();

  chart = new ChartJS(props.chartId, createConfig());
}

/**
 * ## Function Type
 * This function is a listener, and it is attached to a switch (under some conditions).
 * **Do ___not___ call this function standalone**. Only attach this function as a listener to an event.
 *
 * ## Overview
 * Flips the `displayCumulative` global boolean variable and takes action based on its values. **When it turns true,** it
 * changes the graph by adding a total of four datasets. Two of them are active and interactable, two of them are hidden.
 * The active ones are line (and not bar) datasets, and their values are calculated by taking the sum of all previous ones.
 * Same thing happens for the additional y-axis, if it's defined. All y-axes turn into logarithmic from linear, including
 * the hidden ones. **When it turns false,** the graph is restored to its original state with the two starting bar datasets.
 *
 */
function onSwitchChange() {
  displayCumulative = !displayCumulative;
  chart.data.datasets = exportDatasets();
  chart.options.scales = exportScaleSettings();
  chart.update();

  updateYAxisTitle(chart);
}

defineExpose({
  onSwitchChange,
  negativeValuesPresentInAnyDataset
});

/**
 * Creates a basic Chart Config with the data passed from the props and mounts it on the Chart Object.
 * Initializes the X and Y Axes data and imports some basic settings (e.g. the title) for the Chart.
 * @return A Hash with 'type', 'data' and 'options' values.
 */
 function createConfig() {

  return {
    type: props.chartType,
    data: {
      labels: props.xAxisData.map(i => i.toString()),
      datasets: exportDatasets()
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: exportScaleSettings(),
      plugins: {
        title: exportTitleOptions(),
        tooltip: {
          enabled: (context) => {
            // Check if the context and dataPoints exist
            if (context && context.tooltip && context.tooltip.dataPoints.length > 0) {
              // Get the datasetIndex from the dataPoint
              const datasetIndex = context.tooltip.dataPoints[0].datasetIndex;
              const datasets = exportDatasets(); // Ensure to get the datasets again if necessary

              // Check if the dataset's yAxisID is 'y1'
              if (datasets[datasetIndex] && datasets[datasetIndex].yAxisID === 'y1') {
                return false; // Disable tooltip
              }
            }
            return true; // Enable tooltip
          },
          callbacks: {
            label: (context) => showTooltips(context)
          },
          bodyFont: {
            size: 17
          },
        },
        zoom: {
          zoom: {
            onZoom: (context) => updateYAxisTitle(context),
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true
            },
            mode: 'x'
          }
        },
        legend: {
          display: true,
          onClick: (e, legendItem, legend) => {

            if (legend.chart.data.datasets.length <= 2)
              return false;
            else if (legendItem.text === '')
              return false;

            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          },
        }
      },
      interaction: {
        mode: 'nearest',
        intersect: true,
        axis: 'x',
        includeInvisible: false,  // Prevent interaction with hidden datasets
      }
    }
  }
}

/**
 * Inspects the Y-Axis and its maximum value and: (1) if the maximum value is more than 100,000, then the
 * title of the corresponding Y-Axis, will have an extra "10³ ×" in it, or (2) the title will not be affected (or the
 * "10³ ×" literal will be removed) if the value is less than 100,000.
 *
 * This function should be called within a callback, ideally whenever the canvas changes the scope of the Y-Axis values.
 *
 * @param input The chart's data. Contains the Y-Axis ticks, with their min and max values.
 */
function updateYAxisTitle(input) {

  const context = input instanceof ChartJS ? input : input.chart;

  if (context.scales.y.max > 100_000 && !context.options.scales.y.title.text.includes('10³ × ')) {
    context.options.scales.y.title.text = addPowerToLegend(context.options.scales.y.title.text);
    context.update();
  }

  if (context.scales.y.max <= 100_000) {
    context.options.scales.y.title.text = context.options.scales.y.title.text.replace('10³ × ', '');
    context.update();
  }
}

/**
 * Creates a string that is displayed in a box every time the user hovers over a value.
 * The string will always contain the value that the user has hovered over. Then, based on the {@link props}'
 * `tooltips` value, the string might contain some more values; usually "Operational" and "Infrastructure".
 * @param context The chart's data (context) at the specific value hovered at.
 * @return Array[String] {@link Array} of {@link String}s. Each one of these values will be displayed with a linebreak.
 */
function showTooltips(context) {
  // labels to show.
  const strings = [];

  // remove decimals for values above 100,000
  const roundedFormattedValue = context.raw >= 100_000 ? Math.round(context.raw).toLocaleString('en-US') : context.formattedValue;

  // base label push.
  let label = `${context.dataset.label}: ${roundedFormattedValue}`;
  strings.push(label);
  strings.push('');

  // if the additional doesn't exist, return the strings
  if (props.tooltips[context.datasetIndex] === undefined)
    return strings;

  // additional labels ('infrastructure' may or may not be included).

  let additional = props.tooltips[context.datasetIndex][context.dataIndex].trim();

  // Split by new lines and filter out any empty lines.
  const formattedStrings = additional.split('\n').filter(Boolean);

  // Format each item and push to the strings array.
  formattedStrings.forEach(item => {
    strings.push(item);
  });

  // all strings.
  return strings;
}

/**
 * Reads the `xAxisData` and the length of the `yAxisData` and exports the data from it
 * in a way that the Chart JS library reads it. Some of the datasets are dependent on the `showCumulative` boolean value.
 *
 * @return An array of JSON objects. These objects contain properties like this: `label`: `String`, `data`: `Array`, `backgroundColor`: `Color`
 */
function exportDatasets() {

  const datasets = [];

  // this is for the normal bar charts.
  for (let scenario = 0; scenario < props.yAxisData.length; scenario++) {
    const dataHash = {};
    dataHash.label = props.yAxisData[scenario].label;
    dataHash.data = props.yAxisData[scenario].data;
    dataHash.backgroundColor = props.colors[scenario % 2];
    dataHash.borderColor = props.colors[0];
    dataHash.yAxisID = 'y';
    dataHash.barPercentage = 1
    dataHash.categoryPercentage = 1
    datasets.push(dataHash);
  }

  // this is for the second y-axis
  for (let scenario = 0; scenario < props.yAxisData.length; scenario++) {
    datasets.push({
      label: '',
      data: props.yAxisData[scenario].data,
      yAxisID: 'y1',
      type: scenario === 1 ? 'line' : 'bar',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      hoverBackgroundColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverBorderWidth: 0,
      pointRadius: 0,
      barThickness: 0,
    });
  }

  // boolean cumulative is toggled through a switch
  if (displayCumulative) {
    // this is for the cumulative data sets (for base scenario)
    let cumulativeDataset1 = [];
    let cumulativeCounter1 = 0;
    for (let i = 0; i < props.yAxisData[0].data.length; i++) {
      cumulativeCounter1 += parseFloat(props.yAxisData[0].data[i]);
      cumulativeDataset1.push(cumulativeCounter1);
    }

    datasets.push({
      type: 'line',
      label: `Cumulative - ${props.yAxisData[0].label}`,
      data: cumulativeDataset1,
      backgroundColor: `${props.colors[0]}44`,
      borderColor: `${props.colors[0]}a0`,
      pointRadius: 1.5,
    });

    datasets.push({
      label: '',
      data: cumulativeDataset1,
      yAxisID: 'y1',
      type: 'line',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      hoverBackgroundColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverBorderWidth: 0,
      pointRadius: 0,
      barThickness: 0,
    });

    let cumulativeDataset2 = [];
    let cumulativeCounter2 = 0;
    // this is for the cumulative data sets (renovation scenario)
    if (props.yAxisData[1] !== undefined) {

      for (let i = 0; i < props.yAxisData[1].data.length; i++) {
        cumulativeCounter2 += parseFloat(props.yAxisData[1].data[i]);
        cumulativeDataset2.push(cumulativeCounter2);
      }

      cumulativeDataset2.map(value => Math.sign(value) * Math.log2(Math.abs(value)))

      datasets.push({
        type: 'line',
        label: `Cumulative - ${props.yAxisData[1].label}`,
        data: cumulativeDataset2,
        backgroundColor: `${props.colors[1]}44`,
        borderColor: `${props.colors[1]}a0`,
        pointRadius: 1.5,
      });

      datasets.push({
      label: '',
      data: cumulativeDataset2,
      yAxisID: 'y1',
      type: 'line',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      hoverBackgroundColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverBorderWidth: 0,
      pointRadius: 0,
      barThickness: 0,
    });

    }
  }

  return datasets;
}

/**
 * Reads the title option and renders it on the top of the Chart.
 * @return The settings with only the `text` property changed to be the value of the `Title` passed in the props.
 */
function exportTitleOptions () {
  return {
    color: '#161616',
    display: true,
    text: props.title,
    font: {
      size: 16,
    },
    padding: {
      top: 10,
      bottom: 20
    }
  }
}

/**
 * Sends the scale settings defined at the top of this file. Also abbreviates the ticks, if there are values above 100,000.
 * @return An x and y-axis objects, with -mainly- their font settings.
 */
function exportScaleSettings() {
  const scaleSettings = {
    x: {
      responsive: true,
      title: {
        text: props.xAxis,
        display: true,
        font: {
          size: 16
        },
        color: 'black'
      }
    },
    y: {
      type: displayCumulative && !negativeValuesPresentInAnyDataset.value ? 'logarithmic' : 'linear',
      responsive: true,
      position: 'left',
      ticks: {
        callback: (value, index, values) => {
          if (values[values.length - 1].value > 100_000) {
            hasTitleAbbreviation.value = true
            return `${(value / 1000).toLocaleString('en-US')}`;
          }
          return value.toLocaleString('en-US');
        }
      },
      title: {
        text: abbreviationStatus() ? addPowerToLegend(props.yAxis) : props.yAxis,
        display: true,
        font: {
          size: 16
        },
        color: 'black'
      }
    }
  };

  // Add the optional y-axis in charts
  if (props.yAxisOptional) {
    scaleSettings.y1 = {
      type: displayCumulative ? 'logarithmic' : 'linear',
      responsive: true,
      position: 'right',
      grid: {
        drawTicks: false,
        drawOnChartArea: false
      },
      ticks: {
        callback: (value, index, values) => {
          return (value / props.buildingArea).toFixed(1) ;
        },
      },
      title: {
        text: props.yAxisOptional, // Use the optional Y-axis label
        display: true,
        font: {
          size: 16
        },
        color: 'black'
      }
    };
  } else {
    scaleSettings.y1 = {
      type: displayCumulative ? 'logarithmic' : 'linear',

      display: false,
      grid: {
        drawOnChartArea: false
      },
    }
  }

  return scaleSettings;
}

/**
 * Inspects all the y-Axis ticks to see if there are values above 100,000.
 * @return `true` if there is at least one value above 100,000, `false` otherwise.
 */
function abbreviationStatus() {
  if (props.yAxisData.length === 1)
    return props.yAxisData[0].data.some(item => item >= 100_000);

  return props.yAxisData[0].data.some(item => item >= 100_000) ||
         props.yAxisData[1].data.some(item => item >= 100_000);
}

/**
 * Adds the "10³ ×" string next to the units. If it is "Total [ kWh ]"
 * it will be converted to "Total [ 10³ × kWh ].
 * @param axisLegend The string to be converted.
 */
function addPowerToLegend(axisLegend) {
  return axisLegend.replace(/\[\s*([^\]]+)\s* \]/, "[ 10³ × $1 ]");
}

function dynamicAdd(x, y1, y2) {
  chart.config.data.labels.push(x);
  chart.config.data.datasets[0].data.push(y1);
  chart.config.data.datasets[1].data.push(y2);
  chart.update();
}

watch([() => props.yAxisData, () => props.xAxisData], () => {
  chart.config.data.datasets = exportDatasets();
  chart.config.options.scales = exportScaleSettings();
  chart.update();
});

</script>

<template>
  <canvas class="chart-canvas" :id="chartId" ></canvas>
</template>

<style scoped>
  .chart-canvas {
    width: 500px;
    height: 325px;
  }
</style>
