/**
 * ## Overview
 * Uploads an image and modifies text containers based on the file upload state.
 * It also can preview the image when it is done uploading.
 *
 * ## Requirements
 * In order to make it work, you'll need:
 * - A file input (that accepts jpg/png images).
 * - A 'span' (or any text element) with an id "file-selected".
 * - A 'small' (or any text element) with an id "image-preview-text".
 * - An img tag with an id "image-preview".
 * - A submit button with an id "upload-button".
 *
 * ## Events
 * This file will modify the elements based on the upload state of the image. It makes sure it previews both the name
 * and the image itself.
 *
 * #### Image uploading
 * When the user uploads an image, this script write the file name to the 'file-selected' element, and it will display the
 * image preview by hiding the 'small' placeholder text. However big the image might be, it will be displayed **and it's
 * up to you to limit the max upload size and/or dimensions**.
 *
 * #### Cancelling
 * When the user taps the "Choose File" button and then clicks "cancel" on the file upload dialog, it basically cancels
 * the entire upload, and deletes the previous selected image. When this happens, the listener on this script, will detect
 * it and will restore the page on its original state (with the upload button disabled, with the 'small' placeholder text
 * visible, etc.).
 */
export default function imageUploadModifier() {

    // required elements from the page.
    const fileInput = document.querySelector('input[type="file"]');
    const fileSelected = document.getElementById("file-selected");
    const fileInputText = document.getElementById("image-preview-text");
    const imagePreview = document.getElementById("image-preview");
    const uploadButton = document.getElementById('upload-button');

    // when the page loads, there are no files uploaded, so disable the upload button.
    uploadButton.disabled = true;

    // add a listener to the file-input for when it changes.
    fileInput.addEventListener("change", (event) => {

        // target the file uploaded.
        const file = event.target.files[0];

        // conditional upload texts/buttons/image previews
        fileSelected.textContent = file ? file.name : "No file selected";
        fileSelected.className = file ? 'ms-2 font-weight-bold' : 'ms-2 font-italic text-muted';
        fileInputText.className = file ? 'd-none' : '';

        // if there is a valid file uploaded, enable the upload button.
        uploadButton.disabled = !file;

        // if there is a valid file uploaded, preview the image.
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                imagePreview.src = e.target.result;
                imagePreview.style.display = "block";
            };
            reader.readAsDataURL(file);
        }
        // otherwise, when the user cancels the upload, or if there is no file, disable the preview.
        else {
            imagePreview.src = "#";
            imagePreview.style.display = "none";
        }
    });

}

